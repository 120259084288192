@import url('https://fonts.cdnfonts.com/css/sf-pro-display');
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-4xl font-normal;
    }
    h2 {
        @apply text-2xl font-light;
    }
    h3 {
        @apply text-xl font-light;
    }
    h3 {
        @apply text-lg font-light;
    }

    p {
        @apply text-sm text-white font-light;
    }
}

body {
    background-color: #18181b;
    color: white;
}
